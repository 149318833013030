import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { RootState } from '../../store/store';
import { changeTd, logout } from '../../store/userSlice';

export const NavBar = () => {
    const dispatch = useDispatch();
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const td = globalStateUser.td;

    const handleLogout = () => {
        dispatch(logout());
    }

    return (
        <>
            <div className="navbar bg-primary shadow-md">
                <div className="flex-1">
                    <a className="btn btn-ghost normal-case text-xl">JTP by ProjectX</a>
                </div>
                <div className="flex-none">
                    <div className='flex flex-row'>
                        {td && <p className='font-bold'>MTD&nbsp;&nbsp;</p>}
                        {td || <p className='font-bold'>YTD&nbsp;&nbsp;</p>}
                        <input type="checkbox" className="toggle toggle-md" defaultChecked onClick={() => dispatch(changeTd())} />
                    </div>
                    <div className="dropdown dropdown-end">
                        <button tabIndex={0} className="btn btn-square btn-ghost">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" /></svg>
                        </button>
                        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                            <li onClick={handleLogout}><a>Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <Outlet />
        </>
    )
}
