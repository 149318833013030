import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../../store/store';

export const PrivateRoute = () => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const userData = globalStateUser?.token

    return userData ? <Outlet /> : <Navigate to='/' />
};
