import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { userReducer } from './userSlice'

const reducer = combineReducers({
    userData: userReducer
});

const persistConfig = {
    /**
     * add encrypted data persist
     */
    transforms: [
        encryptTransform({
            secretKey: 'webDataEncrypt',
            onError: function (error) {
                // Handle the error.
                console.log(error)
            },
        }),
    ],
    key: 'webDataEncrypt',
    storage
}

const persistedReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
    reducer: persistedReducer,
    devTools: false,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
});
const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof reducer>
