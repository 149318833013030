import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'userData',
    initialState: {
        user: null,
        token: null,
        td: true
    },
    reducers: {
        login: (state, action) => {
            state.user = action.payload
        },
        storeToken: (state, action) => {
            state.token = action.payload
        },
        changeTd: (state) => {
            state.td = !state.td
        },
        logout: (state) => {
            state.user = null
            state.token = null
        },
    }
});

export const userReducer = userSlice.reducer;

export const { login, storeToken, logout, changeTd } = userSlice.actions;
