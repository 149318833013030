import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Login } from './pages/Login/Login';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Route, Routes } from 'react-router-dom';
import { NavBar } from './components/NavBar/NavBar';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { Details } from './pages/Details/Details';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route element={<NavBar />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/details' element={<Details />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
