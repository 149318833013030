import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { PendapatanCard } from '../../components/PendapatanCard/PendapatanCard'
import { RootState } from '../../store/store';

export const Dashboard = () => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const td = globalStateUser.td;
    const [parkingGlobal, setParkingGlobal] = useState({
        yesterday: 0,
        now: 0,
        mtd: 0,
        ytd: 0
    });
    const [ebikeGlobal, setEbikeGlobal] = useState({
        yesterday: 0,
        now: 0,
        mtd: 0,
        ytd: 0
    });
    const [dcGlobal, setDcGlobal] = useState({
        yesterday: 0,
        now: 0,
        mtd: 0,
        ytd: 0
    });

    useEffect(() => {
        axios.get(
            (process.env.REACT_APP_PARKING_GLOBAL as string)
        )
            .then(response => {
                setParkingGlobal({
                    ...parkingGlobal,
                    yesterday: response.data.data.pendapatan_kemaren.pendapatan,
                    now: response.data.data.pendapatan_hari_ini.pendapatan,
                    mtd: response.data.data.pendapatan_mtd.pendapatan,
                    ytd: response.data.data.pendapatan_ytd.pendapatan
                });
            })
            .catch(error => {
                console.log(error);
            });

        axios.get(
            (process.env.REACT_APP_EBIKE_GLOBAL as string)
        )
            .then(response => {
                setEbikeGlobal({
                    ...ebikeGlobal,
                    yesterday: response.data.data.pendapatan_kemaren.pendapatan,
                    now: response.data.data.pendapatan_hari_ini.pendapatan,
                    mtd: response.data.data.pendapatan_mtd.pendapatan,
                    ytd: response.data.data.pendapatan_ytd.pendapatan
                });
            })
            .catch(error => {
                console.log(error);
            });

        axios.get(
            (process.env.REACT_APP_DC_GLOBAL as string)
        )
            .then(response => {
                setDcGlobal({
                    ...dcGlobal,
                    yesterday: response.data.data.pendapatan_kemaren.pendapatan,
                    now: response.data.data.pendapatan_hari_ini.pendapatan,
                    mtd: response.data.data.pendapatan_mtd.pendapatan,
                    ytd: response.data.data.pendapatan_ytd.pendapatan
                });
            })
            .catch(error => {
                console.log(error);
            });
        // setTimeout(() => {
        // }, 1000);
    }, []);

    return (
        <>
            <PendapatanCard title='PARKIR' totalYesterday={td === true ? parkingGlobal.mtd : parkingGlobal.ytd} totalToday={parkingGlobal.now} />
            <PendapatanCard title='EBIKE' totalYesterday={td === true ? ebikeGlobal.mtd : ebikeGlobal.ytd} totalToday={ebikeGlobal.now} />
            <PendapatanCard title='DISTRIBUTION CENTER' totalYesterday={td === true ? dcGlobal.mtd : dcGlobal.ytd} totalToday={dcGlobal.now} />
            <PendapatanCard title='BREAD STORY JTP3' totalYesterday={1000000} pendapatanMtd={100000} />
            <PendapatanCard title='HOTEL SMART TLOGOMAS' roomRevenue={100000} occupation={100000} roomRevenueMtd={100000} occupationMtd={100000} />
        </>
    )
}
