import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginData } from '../../interfaces';
import { RootState } from '../../store/store';
import { login, storeToken } from '../../store/userSlice';

export const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<LoginData>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const token = globalStateUser.user;

    useEffect(() => {
        if (token) {
            navigate('/dashboard');
        }
    }, []);

    const onSubmit = handleSubmit(data => {
        const dataLogin = {
            username: data.email,
            password: data.password
        }
        axios.post(
            (process.env.REACT_APP_LOGIN as string),
            dataLogin
        )
            .then(response => {
                dispatch(storeToken(response.data.data.token))
                dispatch(login(response.data.data.user));
                navigate('/dashboard');
            })
            .catch(error => {
                console.log(error);
            });
    });

    return (
        <>
            <div className="hero min-h-screen bg-base-200">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <div className="text-center lg:text-left">
                        <h1 className="text-5xl font-bold">Login now!</h1>
                        <p className="py-6">JTP Project by ProjectX.</p>
                    </div>
                    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
                        <div className="card-body">
                            <form onSubmit={onSubmit}>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Username</span>
                                    </label>
                                    <input type="text" placeholder="username" className={`${!errors.email?.type ? 'input input-bordered' : 'input border-2 border-error'}`} {...register('email', { required: true })} />
                                    <div className="label justify-start">
                                        {errors.email ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                                        <span className='text-error text-sm font-bold'>
                                            {errors.email?.type === "required" && "Username required"}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Password</span>
                                    </label>
                                    <input type="password" placeholder="password" className={`${!errors.password?.type ? 'input input-bordered' : 'input border-2 border-error'}`} {...register('password', { required: true })} />
                                    <div className="label justify-start">
                                        {errors.password ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                                        <span className='text-error text-sm font-bold'>
                                            {errors.password?.type === "required" && "Password required"}
                                        </span>
                                    </div>
                                    <label className="label">
                                    </label>
                                </div>
                                <div className="form-control mt-6">
                                    <button className="btn btn-primary">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
