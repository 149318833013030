import React from 'react'
import { useSelector } from 'react-redux';
import { rupiahConvert } from '../../helper/rupiahConvert';
import { PendapatanCardProps } from '../../interfaces';
import { RootState } from '../../store/store';

export const PendapatanCard = (props: any) => {
    const { title, totalYesterday, totalToday, roomRevenue, roomRevenueMtd, occupation, occupationMtd, pendapatanMtd }: PendapatanCardProps = props;
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const td = globalStateUser.td;

    return (
        <>
            <h2 className='text-white bg-cyan-600 mx-3 mt-2 rounded-lg text-center font-extrabold text-2xl'>{title}</h2>
            <div className='flex flex-row'>
                {
                    totalToday === 0 || totalToday || pendapatanMtd || roomRevenueMtd || occupationMtd ?
                        <div className="rounded-xl bg-indigo-400 shadow-xl image-full m-2 p-3 text-center h-fit w-1/2">
                            {
                                totalToday &&
                                <>
                                    <p className='text-white'>Pendapatan Hari Ini</p>
                                    <p className='text-white italic'>Rp. {rupiahConvert(totalToday)}</p>
                                </>
                            }
                            {
                                pendapatanMtd &&
                                <>
                                    <p className='text-white'>Pendapatan Kemarin</p>
                                    <p className='text-white italic'>Rp. {rupiahConvert(pendapatanMtd)}</p>
                                </>
                            }
                            {
                                occupationMtd &&
                                <>
                                    <p className='text-white'>Okupansi Kemarin</p>
                                    <p className='text-white italic'>61%</p>
                                </>
                            }
                            {
                                roomRevenueMtd &&
                                <>
                                    <p className='text-white'>Pendapatan Kemarin</p>
                                    <p className='text-white italic'>Rp. {rupiahConvert(roomRevenueMtd)}</p>
                                </>
                            }
                        </div>
                        :
                        null
                }
                {
                    totalYesterday !== 0 || !totalYesterday ?
                        <div className="rounded-xl bg-slate-600 shadow-xl image-full m-2 p-3 text-center h-fit w-1/2">
                            {/* <h2 className='text-white text-center font-extrabold text-2xl'>{title}</h2> */}
                            {
                                totalYesterday &&
                                <>
                                    <p className='text-white'>Pendapatan {td === true ? `MTD` : `YTD`}</p>
                                    <p className='text-white italic'>Rp. {rupiahConvert(totalYesterday)}</p>
                                </>
                            }
                            {
                                occupation &&
                                <>
                                    <p className='text-white'>Okupansi MTD</p>
                                    <p className='text-white italic'>61%</p>
                                </>
                            }
                            {
                                roomRevenue &&
                                <>
                                    <p className='text-white'>Pendapatan MTD</p>
                                    <p className='text-white italic'>Rp. {rupiahConvert(roomRevenue)}</p>
                                </>
                            }
                        </div>
                        :
                        null
                }
            </div>
            <hr className='my-2' />
        </>
    )
}
